(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/keylistener/assets/javascripts/keylistener_desktop.js') >= 0) return;  svs.modules.push('/components/utils/keylistener/assets/javascripts/keylistener_desktop.js');


var svs = svs || {};

(function(svs) {
  'use strict';

  svs.utils = svs.utils || {};
  svs.utils.keylistener = svs.utils.keylistener || {};
  svs.utils.keylistener.lastKeyDown = 0;

  svs.utils.keylistener.KEY_ENTER = 13;
  svs.utils.keylistener.KEY_ESC = 27;

  var listenerStack = [];
  var idCounter = 0;

  if (svs.core.detect.formfactor.desktop()) {
    document.addEventListener('keydown', _handleKeys);
  }

  svs.utils.keylistener.addKeyboardListener = function(eventHandler) {
    var focused = document.activeElement;
    var currentListener;
    var id = 'keyListenerId_' + idCounter++;

    if (focused && focused !== document.body) {
      focused.blur();
      document.body.focus();
    }

    currentListener = {
      eventHandler: eventHandler,
      id: id
    };

    listenerStack.push(currentListener);

    return id;
  };

  svs.utils.keylistener.removeKeyboardListener = function(id) {
    listenerStack = listenerStack.filter(function(listener) {
      return listener.id !== id;
    });
    return true;
  };

  function _handleKeys(event) {
    var listener = _getCurrentListener();

    if (listener) {
      listener.eventHandler(event);
    }
  }

  function _getCurrentListener() {
    var listener = listenerStack[listenerStack.length - 1];

    if (listener) {
      return listener;
    } else if (listenerStack.length > 0) {
      listenerStack.pop();
      _getCurrentListener();
    } else {
      return false;
    }
  }
})(svs);


 })(window);